import {v4 as uuidv4} from 'uuid';

import type {ShopLoginButtonWebComponentProps} from '~/features/ShopLoginButton/types';
import {Bugsnag} from '~/foundation/Bugsnag/Bugsnag';
import {Monorail} from '~/foundation/Monorail/Monorail';
import type {AnalyticsData} from '~/foundation/Monorail/types';
import {createCustomElement} from '~/utils/customElement';
import {isoDocument} from '~/utils/document';
import {
  createElementLocator,
  createElementVisibilityObserver,
} from '~/utils/dom';
import InputListener from '~/utils/input';
import {getStorageItem} from '~/utils/storage';
import {isoWindow} from '~/utils/window';

type AnalyticsBaseData = Required<
  Pick<
    AnalyticsData,
    | 'analyticsContext'
    | 'analyticsTraceId'
    | 'apiKey'
    | 'flow'
    | 'flowVersion'
    | 'shopPermanentDomain'
    | 'source'
  >
>;

export async function initCustomerAccountsSignUp(isWindoidOrRedirect = false) {
  const analyticsTraceId = uuidv4();
  const analyticsData: AnalyticsBaseData = {
    analyticsContext: 'loginWithShopClassicCustomerAccounts',
    analyticsTraceId,
    apiKey: '',
    flow: 'classic_customer_accounts',
    flowVersion: 'sign_up',
    shopPermanentDomain: isoWindow.Shopify?.shop ?? '',
    source: 'initCustomerAccountsSignUp',
  };

  const bugsnag = new Bugsnag('initCustomerAccountsSignUp');

  const monorailTracker = new Monorail({
    analyticsData,
    notify: bugsnag.notify,
  });

  try {
    initClassicCustomerAccountsSignUpForm();
  } catch (error) {
    if (error instanceof Error) {
      bugsnag.notify(error);
    }
  }

  function initClassicCustomerAccountsSignUpForm() {
    const inputListenerMap = new WeakMap<HTMLInputElement, InputListener>();
    let shopLoginButton: HTMLElement | null = null;

    const elementVisibilityObserver =
      createElementVisibilityObserver<HTMLInputElement>({
        onVisible: addSignInWithShopToInput,
        onFallback: (element) => {
          element.addEventListener('focus', handleInputFocus, {once: true});
          monorailTracker.produceMonorailEvent({
            event: {
              payload: {
                ...analyticsData,
                errorCode: 'fallback_to_focus_event',
                errorMessage:
                  'Fallback to focus event for classic customer accounts',
                sdkVersion: '__buildVersionBeta',
              },
              schemaId: 'shopify_pay_login_with_shop_sdk_error_events/1.0',
            },
          });
        },
      });

    createElementLocator<HTMLInputElement>({
      selector:
        'form[data-login-with-shop-sign-up] input[type="email"],form[data-login-with-shop-sign-up] input[name="customer[email]"',
      onElementFound: (input) => elementVisibilityObserver.observe(input),
    });

    function handleInputFocus(event: FocusEvent) {
      const input = event.target as HTMLInputElement;
      addSignInWithShopToInput(input);
    }

    function signUpRedirectUrl(
      form: HTMLFormElement,
      analyticsTraceId: string,
    ): string {
      const checkoutInputUrl = (
        form.elements.namedItem('checkout_url') as HTMLInputElement | undefined
      )?.value;

      const returnInputUrl = (
        form.elements.namedItem('return_url') as HTMLInputElement | undefined
      )?.value;

      /* eslint-disable @typescript-eslint/naming-convention */
      const queryParams = new URLSearchParams({
        analytics_trace_id: analyticsTraceId,
        ...(checkoutInputUrl && {checkout_url: checkoutInputUrl}),
        ...(returnInputUrl && {return_url: returnInputUrl}),
      });
      /* eslint-enable @typescript-eslint/naming-convention */

      const redirectUri = `${
        isoWindow.location.origin
      }/account/redirect?${queryParams.toString()}`;

      return redirectUri;
    }

    function addSignInWithShopToInput(input: HTMLInputElement) {
      const form = input.form;
      if (!form) {
        bugsnag.notify(
          new Error('Email form missing for classic customer accounts'),
        );
        return;
      }

      if (inputListenerMap.has(input)) {
        bugsnag.notify(new Error('Input listener already exists for input'));

        inputListenerMap.get(input)?.destroy();
        inputListenerMap.delete(input);
      }

      // Add hidden analytics trace id to form
      const analyticsTraceIdHiddenInput = isoDocument.createElement(
        'input',
      ) as HTMLInputElement;
      analyticsTraceIdHiddenInput.type = 'hidden';
      analyticsTraceIdHiddenInput.name = 'login_with_shop[analytics_trace_id]';
      analyticsTraceIdHiddenInput.value = analyticsTraceId;
      form.appendChild(analyticsTraceIdHiddenInput);

      // Init login form if it hasn't been initialized yet
      if (!shopLoginButton) {
        const returnUri = signUpRedirectUrl(form, analyticsTraceId);
        shopLoginButton = initCustomerAccountsSignUpPage(
          isWindoidOrRedirect,
          returnUri,
        );

        shopLoginButton.addEventListener('completed', () => {
          isoWindow.location.assign(returnUri);
        });
      }

      const firstNameInput = form.querySelector<HTMLInputElement>(
        'input[name="customer[first_name]"',
      );
      const lastNameInput = form.querySelector<HTMLInputElement>(
        'input[name="customer[last_name]"',
      );

      shopLoginButton.setAttribute('firstName', firstNameInput?.value || '');
      shopLoginButton.setAttribute('lastName', lastNameInput?.value || '');
      shopLoginButton.setAttribute('email', input.value);

      // Add input listener to email input
      inputListenerMap.set(
        input,
        new InputListener(input, (value) => {
          if (!shopLoginButton) {
            return;
          }

          shopLoginButton.setAttribute(
            'firstName',
            firstNameInput?.value || '',
          );
          shopLoginButton.setAttribute('lastName', lastNameInput?.value || '');
          shopLoginButton.setAttribute('email', value);
        }),
      );
    }
  }
}

function initCustomerAccountsSignUpPage(
  isWindoidOrRedirect: boolean,
  returnUri?: string,
): HTMLElement {
  const documentDoesNotContainSignInForm =
    isoDocument.querySelector(
      'form[data-login-with-shop-sign-in] input[type="email"],form[data-login-with-shop-sign-in] input[name="customer[email]"',
    ) === null;

  const signInWithShopModalWasDismissed =
    getStorageItem('signInWithShop:modalDismissed') === 'true';
  const shouldAutoOpen =
    documentDoesNotContainSignInForm && !signInWithShopModalWasDismissed;

  const shopLoginButton = createCustomElement(
    'shop-login-button',
  ) as HTMLElement;

  /* eslint-disable @typescript-eslint/naming-convention */
  const props: Partial<ShopLoginButtonWebComponentProps> = {
    'analytics-context': 'loginWithShopClassicCustomerAccounts',
    ...(shouldAutoOpen ? {'auto-open': true} : {}),
    'client-id': '',
    'disable-sign-up': true,
    'flow-version': 'sign_up',
    'hide-button': true,
    proxy: true,
    ...(isWindoidOrRedirect && {
      'response-type': 'code',
      ...(returnUri && {'return-uri': returnUri}),
      'ux-mode': 'windoid',
    }),
  };
  /* eslint-enable @typescript-eslint/naming-convention */

  Object.entries(props).forEach(([key, value]) => {
    shopLoginButton.setAttribute(key, String(value));
  });

  isoDocument.body.appendChild(shopLoginButton);

  return shopLoginButton;
}
